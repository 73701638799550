import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AdminInfo, AuctionsSectionData, AdminArtworksSectionData, AdminUsersSectionData, AdminDiscipline, AdminSmartTagsSectionData, AdminSmartTagsTotalSectionData } from '@/types/interfaces/global/admin/AdminInterface';
import { User } from '@/types/interfaces/global/UserInterface';
import { AdminActions, AdminMutations } from '@/store/enums/AdminEnums';
import store from '@/store';
import axios from '@/core/plugins/axios';
import { Artwork } from '@/types/interfaces/global/ArtworkInterface';
import { Auction } from '@/types/interfaces/global/MarketInterface';

@Module
export default class AdminModule extends VuexModule implements AdminInfo {
  users = {} as [User | undefined];
  userSectionData = {} as AdminUsersSectionData | undefined;
  usersTotal = {} as number;

  auctions = {} as [Auction | undefined];
  auction = {} as Auction | undefined;
  auctionSectionData = {} as AuctionsSectionData | undefined;
  auctionsTotal = {} as number;
  
  artwork = {} as Artwork | undefined;
  artworks = {} as [Artwork | undefined];
  artworksSectionData = {} as AdminArtworksSectionData | undefined;
  artworksTotal = {} as number;
  
  smartTagsSectionData = {} as [AdminSmartTagsSectionData | undefined];
  smartTagsTotalSectionData = {} as AdminSmartTagsTotalSectionData | undefined;

  disciplines = {} as [AdminDiscipline | undefined];

  /**
   * Admin Section data
   */
  get getUsersSectionData(): AdminUsersSectionData | undefined {
    return this.userSectionData;
  }

  /**
   * Get current user object
   * @returns [User | undefined]
   */
  get getUsers(): [User | undefined] {
    return this.users;
  }

  /**
   * Get current user object
   * @returns [User | undefined]
   */
  get getTotalUsers(): number {
    return this.usersTotal;
  }

  /**
   * @returns SectionData | undefined
   */
  get getAdminAuctionSectionData(): AuctionsSectionData | undefined {
    return this.auctionSectionData;
  }

  get getAdminDisciplines() {
    return this.disciplines;
  }

  /**
   * @returns [AdminSmartTagsSectionData | undefined]
   */
  get getAdminSmartTags(): [AdminSmartTagsSectionData | undefined] {
    return this.smartTagsSectionData;
  }

  get getAdminSmartTagsTotalSectionData(): AdminSmartTagsTotalSectionData | undefined {
    return this.smartTagsTotalSectionData;
  }

  /**
   * @returns [Auction | undefined]
   */
  get getAdminAuctions(): [Auction | undefined] {
    return this.auctions;
  }

  get getAdminAuction(): Auction | undefined {
    return this.auction;
  }

  get getAdminTotalAuctions(): number {
    return this.auctionsTotal;
  }

  get getAdminArtwork(): Artwork | undefined {
    return this.artwork;
  }

  get getAdminArtworks(): [Artwork | undefined] {
    return this.artworks;
  }

  get getAdminTotalArtworksSectionData(): AdminArtworksSectionData | undefined {
    return this.artworksSectionData;
  }

  get getAdminTotalArtworks(): number | undefined {
    return this.artworksTotal;
  }

  get getAdminTotalArtworksToSell(): number | undefined {
    return this.artworksSectionData?.total_artworks_on_sale;
  }

  get getAdminTotalArtworksToAuction(): number | undefined {
    return this.artworksSectionData?.total_artworks_on_auction;
  }

  @Mutation
  [AdminMutations.SET_ADMIN_SECTION_DATA](userSectionData) {
    this.userSectionData = userSectionData;
  }

  @Mutation
  [AdminMutations.SET_ADMIN_USERS](users) {
    this.users = users;
  }

  @Mutation
  [AdminMutations.SET_ADMIN_USERS_TOTAL](total) {
    this.usersTotal = total;
  }

  @Mutation
  [AdminMutations.SET_ADMIN_AUCTION_SECTION_DATA](auctionSectionData) {
    this.auctionSectionData = auctionSectionData;
  }

  @Mutation
  [AdminMutations.SET_ADMIN_AUCTIONS](auctions) {
    this.auctions = auctions;
  }

  @Mutation
  [AdminMutations.SET_ADMIN_AUCTION](auction) {
    this.auction = auction;
  }

  @Mutation
  [AdminMutations.SET_ADMIN_AUCTIONS_TOTAL](auctionsTotal) {
    this.auctionsTotal = auctionsTotal;
  }

  @Mutation
  [AdminMutations.SET_ADMIN_ARTWORK](artwork) {
    this.artwork = artwork;
  }

  @Mutation
  [AdminMutations.SET_ADMIN_ARTWORKS](artworks) {
    this.artworks = artworks;
  }

  @Mutation
  [AdminMutations.SET_ADMIN_ARTWORKS_TOTAL](artworksTotal) {
    this.artworksTotal = artworksTotal;
  }

  @Mutation
  [AdminMutations.SET_ADMIN_ARTWORK_SECTION_DATA](artworksSectionData) {
    this.artworksSectionData = artworksSectionData;
  }

  @Mutation
  [AdminMutations.SET_ADMIN_DISCIPLINES](disciplines) {
    this.disciplines = disciplines;
  }

  @Mutation
  [AdminMutations.SET_ADMIN_SMART_TAGS_SECTION_DATA](smartTagsSectionData) {
    this.smartTagsSectionData = smartTagsSectionData;
  }

  @Mutation
  [AdminMutations.SET_ADMIN_SMART_TAGS_TOTAL_SECTION_DATA](smartTagsTotalSectionData) {
    this.smartTagsTotalSectionData = smartTagsTotalSectionData;
  }

  @Action
  async [AdminActions.LOAD_ADMIN_USERS_SECTION_DATA]() {
    try {
      const userSectionData = await axios.get(`/private/administration/users/section-data`);

      store.commit(AdminMutations.SET_ADMIN_SECTION_DATA, userSectionData.data.data);
    } catch (error: any) {
      console.error('ERROR', error);
    }
  }

  @Action
  async [AdminActions.LOAD_USERS]({ per_page, page, filters }) {
    try {
      const response = await axios.get('/private/administration/users/list/' + per_page + '/' + page, {
        params: { filters }
      });
      const users = response.data.data;
      if (users) {
        store.commit(AdminMutations.SET_ADMIN_USERS, users.data);
        store.commit(AdminMutations.SET_ADMIN_USERS_TOTAL, users.total);
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  @Action
  async [AdminActions.LOAD_ADMIN_AUCTIONS_SECTION_DATA]() {
    try {
      const auctionSectionData = await axios.get(`/private/administration/auction/section-data`);

      store.commit(AdminMutations.SET_ADMIN_AUCTION_SECTION_DATA, auctionSectionData.data.data);
    } catch (error: any) {
      console.error('ERROR', error);
    }
  }

  @Action
  async [AdminActions.LOAD_ADMIN_DISCIPLINES]() {
    const currentRole = store.getters.currentRole;
    try {
      const response = await axios.get(`/public/artwork/disciplines/list`);
      this.context.commit(AdminMutations.SET_ADMIN_DISCIPLINES, response.data.data);
    } catch (e) {
      console.error('ERROR', e);
    }
  }

  @Action
  async [AdminActions.LOAD_AUCTIONS]({ per_page, page, filters }) {
    try {
      const response = await axios.get('/private/administration/auction/list/' + per_page + '/' + page, {
        params: { filters }
      });
      const auctions = response.data.data;
      if (auctions) {
        store.commit(AdminMutations.SET_ADMIN_AUCTIONS, auctions.data);
        store.commit(AdminMutations.SET_ADMIN_AUCTIONS_TOTAL, auctions.total);
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  @Action
  async [AdminActions.LOAD_AUCTION]({ artworkHash, auctionHash }) {
    try {
      const response = await axios.get(
        '/private/administration/artwork/' + artworkHash + '/auction/view/' + auctionHash
      );
      const auction = response.data.data;
      if (auction) {
        store.commit(AdminMutations.SET_ADMIN_AUCTION, auction);
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  @Action
  async [AdminActions.LOAD_ADMIN_ARTWORK](artworkHash) {
    try {
      const response = await axios.get('/private/administration/artwork/view/' + artworkHash);
      const artwork = response.data.data;

      if (artwork) {
        store.commit(AdminMutations.SET_ADMIN_ARTWORK, artwork);
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      return false;
    }
  }

  @Action
  async [AdminActions.LOAD_ADMIN_ARTWORKS]({ filters, per_page, page }) {
    try {
      const response = await axios.get(`private/administration/artwork/list/${per_page}/${page}`, {
        params: { 
          filters: filters
        }
      });
      const artworks = response.data.data;

      if (artworks) {
        store.commit(AdminMutations.SET_ADMIN_ARTWORKS, artworks.data);
        store.commit(AdminMutations.SET_ADMIN_ARTWORKS_TOTAL, artworks.total);
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      return false;
    }
  }

  @Action
  async [AdminActions.LOAD_ADMIN_ARTWORKS_SECTION_DATA]() {
    try {
      const response = await axios.get(`private/administration/artwork/total`);
      const artworks = response.data.data;
      const total_artworks = artworks.totalArtworks;
      const total_artworks_highlighted = artworks.totalArtworksHighlighted;
      const total_artworks_on_sale = artworks.totalArtworksOnSale;
      const total_artworks_on_auction = artworks.totalArtworksOnAuction;
    
      store.commit(AdminMutations.SET_ADMIN_ARTWORK_SECTION_DATA, {
        total_artworks: total_artworks,
        total_artworks_highlighted: total_artworks_highlighted,
        total_artworks_on_sale: total_artworks_on_sale,
        total_artworks_on_auction: total_artworks_on_auction
      });

    } catch (error: any) {
      console.error('ERROR', error);
    }
  }

  @Action
  async [AdminActions.LOAD_ADMIN_SMART_TAGS_SECTION_DATA]({ filters, per_page, page }) {
    try {
      const response = await axios.get('private/administration/smart-tags/list/' + per_page + '/' + page, {
        params: { filters }
      });
      const smartTags = response.data.data;

      if (smartTags) {
        store.commit(AdminMutations.SET_ADMIN_SMART_TAGS_SECTION_DATA, smartTags.data);
        return true;
      } else {
        return false;
      }

    } catch (error: any) {
      console.error('ERROR', error);
    }
  }

  @Action
  async [AdminActions.LOAD_ADMIN_SMART_TAGS_TOTAL_SECTION_DATA]() {
    try {
      const response = await axios.get(`private/administration/smart-tags/total`);
      const smart_tags = response.data.data;
      const total_smart_tags = smart_tags.totalSmartTags;
      const total_smart_tags_in_use = smart_tags.totalSmartTagsInUse;
      const total_smart_tags_free = smart_tags.totalSmartTagsFree;
    
      store.commit(AdminMutations.SET_ADMIN_SMART_TAGS_TOTAL_SECTION_DATA, {
        total_smart_tags: total_smart_tags,
        total_smart_tags_in_use: total_smart_tags_in_use,
        total_smart_tags_free: total_smart_tags_free,
      });

    } catch (error: any) {
      console.error('ERROR', error);
    }
  }

  @Action
  async [AdminActions.ADD_BID_TO_AUCTION]({ artworkHash, auctionHash, amount, local_identifier }) {
    return await axios
      .post('/private/administration/artwork/' + artworkHash + '/auction/add-bid/' + auctionHash, {
        amount: amount,
        local_identifier: local_identifier
      })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action
  async [AdminActions.TOGGLE_ADMIN_HIGHLIGHTED]({ artworkHash }) {
    return await axios
      .post('/private/administration/artwork/' + artworkHash + '/toggle-visibility-highlighted')
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action
  async [AdminActions.TOGGLE_VISIBILITY]({ artworkHash, auctionHash }) {
    return await axios
      .post('/private/administration/artwork/' + artworkHash + '/auction/toggle-visibility/' + auctionHash)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action
  async [AdminActions.TOGGLE_VISIBILITY_TIMER]({ artworkHash, auctionHash }) {
    return await axios
      .post('/private/administration/artwork/' + artworkHash + '/toggle-visibility-timer/' + auctionHash)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action
  async [AdminActions.TOGGLE_CLOSE_SOON]({ artworkHash, auctionHash }) {
    return await axios
      .post('/private/administration/artwork/' + artworkHash + '/auction/toggle-will-close-soon/' + auctionHash)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action
  async [AdminActions.TOGGLE_PHYSICAL_LOCATION]({ artworkHash, auctionHash }) {
    return await axios
      .post('/private/administration/artwork/' + artworkHash + '/auction/toggle-has-physical-location/' + auctionHash)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action
  async [AdminActions.CONFIRM_USER](userHash) {
    return await axios
      .post('/private/administration/users/' + userHash + '/confirm')
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action
  async [AdminActions.CONFIRM_DOC_RECEIVED](userHash: string) {
    return await axios
      .post('/private/administration/users/' + userHash + '/doc-received')
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action
  async [AdminActions.TOGGLE_USER_VISIBILITY](userHash) {
    return await axios
      .post('/private/administration/users/' + userHash + '/toggle-visibility')
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }
}
