enum ArtworksActions {
  // action types
  LOAD_ARTWORK_SECTION_DATA = 'loadArtworkSectionData',
  LOAD_ARTWORKS = 'loadArtworks',
  LOAD_ARTWORK = 'loadArtwork',
  LOAD_DISCIPLINES = 'loadDisciplines',
  SAVE_ARTWORK = 'saveArtwork',
  EDIT_ARTWORK = 'EDIT_ARTWORK',
  TOGGLE_ARTWORK_VISIBILITY = 'TOGGLE_ARTWORK_VISIBILITY',
  SAVE_ARTWORK_TO_AUCTION = 'SET_ARTWORK_TO_AUCTION',
  SAVE_ARTWORK_TO_SALE = 'SET_ARTWORK_TO_SALE',
  CANCEL_AUCTION = 'cancelAuction',
  CANCEL_SELL = 'cancelSell',
  CREATE_NFT = 'createNFT',
  GET_SMART_TAG_IS_USED = 'getSmartTagIsUsed',
}

enum ArtworkMutations {
  // mutation types
  SET_ARTWORKS = 'setArtworks',
  SET_ARTWORK = 'setArtwork',
  ADD_ARTWORK = 'ADD_ARTWORK',
  SET_ARTWORKS_TOTAL = 'setArtworksTotal',
  SET_SECTION_DATA = 'setSectionData',
  SET_ARTWORKS_IN_AUCTION = 'setArtworksInAuction',
  SET_ARTWORK_IN_AUCTION = 'SET_ARTWORK_IN_AUCTION',
  SET_ARTWORKS_FOR_SALE = 'setArtworksForSale',
  SET_ARTWORK_FOR_SALE = 'SET_ARTWORK_FOR_SALE',
  UPDATE_ARTWORK_FOR_SALE = 'UPDATE_ARTWORK_FOR_SALE',
  SET_ARTWORK_FOR_AUCTION = 'SET_ARTWORK_FOR_AUCTION',
  UPDATE_ARTWORK_FOR_AUCTION = 'UPDATE_ARTWORK_FOR_AUCTION',
  SET_ERROR = 'setError',
  SET_DISCIPLINES = 'setDisciplines',
  SET_SMART_TAG_IS_USED = 'setSmartTagIsUsed',
}

export { ArtworksActions, ArtworkMutations };
