export default {
  // Poner los textos que se repiten dentro de este array.
  default: {
    continue: 'Continue',
    cancel: 'Cancel',
  },
  Web3: {
    Errors: {
      // Web3Enums -> Web3Errors
      METAMASK_NOT_INSTALLED: 'Por favor, instala la extensión de Metamask',
      NO_BLOCKCHAIN_PROVIDER: 'No hay proveedor de Blockchain',
      WALLET_NOT_SELECTED: 'Necesitamos que selecciones una Wallet para poder usar la web con normalidad'
    }
  },
  Swal: {
    questions: {
      refresh_page: {
        title: 'Vas a recargar la página',
        text: 'Si continuas puede que pierdas cambios que no hayas guardado.',
        confirm_button: 'Continuar',
        cancel_button: 'Cancelar'
      }
    },
    success: {
      title: '¡Perfecto!',
      text: {
        standard: ''
      },
      confirm_button: {
        standard: 'Continuar'
      }
    }
  },
  Artworks: {
    list: {
      types: {
        all: 'Todos',
        auction: 'Subasta',
        auctionEuro: 'Subasta Euro',
        auctionCrypto: 'Subasta Crypto',
        directSell: 'Venta directa',
        directSellMixed: 'Venta mixta',
        directSellEuro: 'Venta directa Euro',
        directSellCrypto: 'Venta directa Crypto',
        notForSale: 'No en venta'
      }
    }
  },
  ErrorHandler: {
    Auth: {
      Login: {
        AccesDenied: "You can't  perform that action",
        RegistrationIncomplete: 'You must finish the registration',
        AccountNotVerified: 'Unverified account',
        Unauthenticated: 'Unidentified user',
        ItemNotFound: 'Item not found',
        InvalidRoute: 'Invalid route',
        Unknown: 'Unknown',
        GeneralError: 'There has been an error, check the data'
      },
      Register: {
        AccesDenied: "You can't perform that action",
        RegistrationIncomplete: 'You must finish the registration',
        AccountNotVerified: 'Unverified account',
        Unauthenticated: 'Unidentified user',
        ItemNotFound: 'Item not found',
        InvalidRoute: 'Invalid route',
        Unknown: 'Unknown',
        GeneralError: 'There has been an error, check the data'
      }
    }
  },
  Customers: {
    Invoices: 'Invoices',
    OrderId: 'Order ID',
    Amount: 'Amount',
    Status: 'Status',
    Date: 'Date',
    Invoice: 'Invoice',
    PaymentMethods: 'Payment Methods',
    Primary: 'Primary',
    ChargeMethods: 'Charge Methods',
    SetPrimary: 'Set as Primary',
    PaymentRecords: 'Payment Records',
    Artworks: 'Artworks list',
    AttachedDocs: 'Attached Docs',
    Collections: 'Collections'
  },

  auth: {
    error: {
      title: 'Error',
      text: 'Ha ocurrido un error, revisa los datos.',
      ok: 'Aceptar'
    },
    pending: {
      title: 'Cuenta pendiente de confirmar',
      text: 'Nos pondremos en contacto cuando activemos las funcionalidades de tu cuenta',
      ok: 'Entendido',
      form_action: 'Rellenar formulario'
    },
    register: {
      completed_title: '¡Enhorabuena, Has completado el registro inicial!',
      completed_description: 'A continuación te daremos información más detallada del proceso de onboarding.',
      completed_button: 'Continuar'
    },
    typebilling: {
      business: 'Empresa',
      freelance: 'Autónomo'
    },
    billingAddress_nif: 'N.I.F.',
    billingAddress_cif: 'C.I.F.',
    billingAddress_business_name: 'Nombre de empresa',
    billingAddress_city: 'Ciudad',
    billingAddress_cp: 'Código Postal',
    billingAddress_region: 'Provincia',
    billingAddress_country: 'Pais',
    billingAddress_address: 'Dirección',
    billingAddress_phone: 'Teléfono de contacto',
    billingAddress_surname: 'Apellidos',
    billingAddress_name: 'Nombre',
    billing_info: 'Dirección de facturación',
    shippingAddress_city: 'Ciudad',
    shippingAddress_cp: 'Código Postal',
    shippingAddress_region: 'Provincia',
    shippingAddress_country: 'Pais',
    shippingAddress_address: 'Dirección',
    shippingAddress_aditional_info: 'Información adicional',
    shippingAddress_phone: 'Teléfono de contacto',
    shippingAddress_name: 'Nombre descriptivo',
    shipping_info: 'Dirección de envío',
    MuseumAccount: 'Gestión de Colecciones',
    MuseumsDescription: 'Museos, Galerias, Coleccionistas, etc',
    pendiente: 'Estimado <strong>%{valor1}</strong>, hemos recibido su información correctamente.',
    createAccount: 'Create Account',
    newHere: 'New Here?',
    userAccount: 'User Account <strong>%{valor1}</strong> and password <strong>qwerty123456</strong> to continue',
    Email: 'Email',
    Password: 'Password',
    forgotPassword: ' Forgot Password ?',
    Continue: 'Continue',
    or: 'OR',
    pleaseWait: ' Please wait...',
    continueWithPlat: 'Continue with %{plat}',
    ChooseAccountType: 'Choose Account Type',
    NeedMoreInfo: 'If you need more info, please check out',
    AlreadyAccount: 'Already have an account?',
    SignInHere: 'Sign in here',
    FirstName: 'First Name',
    LastName: 'Last Name',
    ConfirmPassword: 'Confirm Password',
    UseNumbersSymbols: 'Use 8 or more characters with a mix of letters, numbers & symbols',
    IAgree: 'I Agree & ',
    TermsConditions: 'Terms and conditions',
    Submit: 'Submit',
    NameRequired: 'Name is a required field',
    SurnameRequired: 'Surname is a required field',
    EmailRequired: 'Email is a required field',
    PasswordRequired: 'Password is a required field',
    PasswordConfirmationRequired: 'Password Confirmation is a required field',
    PassConditions: 'Use 8 or more characters with a mix of letters, numbers & symbols.',
    minchar: 'Password must have at lest 4 characters',
    EmailBeValid: 'Email must be a valid email',
    PasswordsMustMatch: 'Passwords must match',
    PasswordLeastCharacters: 'Password must be at least 4 characters',
    CredentialsIncorrect: 'The provided credentials are incorrect',
    TryAgain: 'Try again!',
    SuccessfullyLogged: 'You have successfully logged in!',
    GotIt: 'Ok, got it!',
    ForgotPassword: 'Forgot Password ?',
    ResetPassword: 'Enter your email to reset your password.',
    Cancel: 'Cancel',
    LogoReg: 'media/logos/logo-reg.svg',
    AccountType: 'Account Type',
    SetupAccountRol: 'Setup Your Account Rol',
    FunctionPlans: 'Function & Plans',
    HowWillAct: 'Choose how you will act in our platform',
    Details: 'Details',
    YourInfo: 'Your Related Info',
    PayMethods: 'Pay Methods',
    SetMethods: 'Set Your Payment Methods',
    Completed: 'Completed',
    WeAreHere: 'Woah, we are here',
    ChooseAccounttype: 'Choose Account Type',
    MoreInfoCheckOut: 'If you need more info, please check out',
    HelpPage: 'Help Page',
    BuyerAccount: 'Buyer Account',
    TradeWithCrypto: 'You can trade with crypto or fiat',
    ArtAccount: 'Art Account',
    ArtistOrDealers: 'Artist or dealers selling works',
    ManagerAccount: 'Manager',
    ManagerAccountDesription: 'Museums, collectors, ...',
    AccountInfo: 'Account Info',
    SpecifyAccountFunction: 'Specify Account Function',
    Artist: 'Artist',
    Dealer: 'Dealer',
    Gallery: 'Gallery',
    Museum: 'Museum',
    CustomersDescriptor: 'Customers will see this shortened version of your statement descriptor',
    AccountName: 'Account Name',
    AccountNameRequired: 'Account Name is a required field',
    SelectAccountPlan: 'Select Account Plan',
    FREE: 'FREE ',
    StartUsingPlatform: 'To start using our platform',
    EnterLevelBenefits: 'Enter a new level of benefits',
    ForDemandingCustomers: 'For the most demanding customers',
    AccountDetails: 'Account Details',
    Name: 'Name',
    Surname: 'Surname',
    Phone: 'Phone',
    ContactEmail: 'Contact Email',
    BillingDetails: 'Billing Details',
    NameOnCard: 'Name On Card',
    CardNumber: 'Card Number',
    ExpirationDate: 'Expiration Date',
    SaveCard: 'Save Card for further billing?',
    MoreInfoBudgetPlanning: 'If you need more info, please check budget planning ',
    SaveCardOnly: 'Save Card',
    YourAreDone: 'Your Are Done!',
    WritingHeadlinesForBlog:
      'Writing headlines for blog posts is as much an art as it is a science and probably warrants its own post, but for all advise is with what works for your great & amazing audience.',
    NeedAttention: 'We need your attention!',
    ToStartGreatTools: 'To start using great tools, please, please',
    CreateTeamPlatform: 'Create Team Platform',
    Address: 'Address',
    AddressName: 'Address name',
    AddressNamePlaceholder: 'Home',
    Postal: 'Postal Code',
    City: 'City',
    Country: 'Country',
    Region: 'Region',
    logout: {
      action: 'Cerrar sesión',
      alert: {
        title: '¿Estás seguro que quieres salir?',
        text: 'Los cambios que no hayas guardado se perderán',
        confirm: 'Sí, cerrar sesión',
        cancel: 'Cancelar'
      }
    }
  },
  footer: {
    About: 'About',
    Contact: 'Contact',
    ContactUs: 'Contact Us'
  },
  siginto: 'Sign In to',
  dashboard: 'Dashboard',
  layoutBuilder: 'Layout builder',
  craft: 'Crafted',
  pages: 'Pages',
  profile: 'Profile',
  profileOverview: 'Overview',
  projects: 'Projects',
  campaigns: 'Campaigns',
  documents: 'Documents',
  connections: 'Connections',
  wizards: 'Wizards',
  horizontal: 'Horizontal',
  vertical: 'Vertical',
  account: 'Account',
  accountOverview: 'Overview',
  settings: 'Settings',
  authentication: 'Authentication',
  basicFlow: 'Basic Flow',
  signIn: 'Sign-in',
  signUp: 'Sign-up',
  passwordReset: 'Password Reset',
  multiStepSignUp: 'Multi-steps Sign up',
  error404: 'Error 404',
  error500: 'Error 500',
  apps: 'Apps',
  chat: 'Chat',
  privateChat: 'Private Chat',
  groupChat: 'Group Chat',
  drawerChat: 'Drawer Chat',
  widgets: 'Widgets',
  widgetsLists: 'Lists',
  widgetsStatistics: 'Statistics',
  widgetsCharts: 'Charts',
  widgetsMixed: 'ArtDevices',
  widgetsTables: 'Tables',
  widgetsFeeds: 'Feeds',
  changelog: 'Changelog',
  docsAndComponents: 'Docs & Components',
  megaMenu: 'Mega Menu',
  exampleLink: 'Example link',
  modals: 'Modals',
  general: 'General',
  inviteFriends: 'Invite Friends',
  viewUsers: 'View Users',
  upgradePlan: 'Upgrade Plan',
  shareAndEarn: 'Share & Earn',
  forms: 'Forms',
  newTarget: 'New Target',
  newCard: 'New Card',
  newAddress: 'New Address',
  createAPIKey: 'Create API Key',
  twoFactorAuth: 'Two Factor Auth',
  createApp: 'Create App',
  createAccount: 'Create Account',
  documentation: 'Documentation',
  resources: 'Resources',
  activity: 'Activity',
  customers: 'Customers',
  gettingStarted: 'Getting Started',
  customersListing: 'Customers Listing',
  customerDetails: 'Customers Details',
  calendarApp: 'Calendar',
  subscriptions: 'Subscriptions',
  getStarted: 'Getting Started',
  subscriptionList: 'Subscription List',
  addSubscription: 'Add Subscription',
  viewSubscription: 'View Subscription',
  Download: 'Download',

  trans: {
    register: {
      pending: {
        title: 'Registration pending',
        subtitle: 'Thank you very much for your interest!',
        info: 'In order to validate your Artist account we need you to share more information about yourself and your work. Please fill in the following Form and we will contact you as soon as possible.',
        info_received: 'We have received your information and are processing your request.',
        contact: 'We will be in touch soono'
      },
      pending_email: {
        title: 'Verify email',
        info: 'In order to continue you have to verify your email, if you have not received the confirmation email click on "Resend confirmation email"',
        verify_email: {
          button: 'Resend confirmation email',
          success_title: 'Email sent',
          success_description: 'The confirmation email has been sent successfully'
        }
      },
      required: {
        roleable: 'Choose one type is required',
        name: 'Por favor, introduce tu nombre',
        surname: 'Por favor, introduce tus apellidos',
        phone: 'Por favor, introduce tu teléfono',
        contact_email: 'Por favor, introduce un correo de contacto',

        shippingAddress_name: 'Por favor, introduce un nombre',
        shippingAddress_address: 'Por favor, introduce una dirección',
        shippingAddress_country: 'Por favor, selecciona un país',
        shippingAddress_phone: 'Por favor, un teléfono de contacto',
        shippingAddress_region: 'Por favor, selecciona una província',
        shippingAddress_cp: 'Por favor, introduce un código postal',
        shippingAddress_city: 'Por favor, introduce una ciudad',

        billingAddress_business_name: 'Por favor, introduce el nombre de la sociedad',
        billingAddress_name: 'Por favor, indica tu nombre',
        billingAddress_surname: 'Por favor, introduce tus apellidos',
        billingAddress_address: 'Por favor, introduce una dirección',
        billingAddress_nif: 'Por favor, indica un documento legal',
        billingAddress_phone: 'Por favor, un teléfono de contacto',
        billingAddress_country: 'Por favor, selecciona un país',
        billingAddress_region: 'Por favor, selecciona una región',
        billingAddress_city: 'Por favor, introduce una ciudad',
        billingAddress_cp: 'Por favor, introduce un código postal',
        billingAddress_type: 'Por favor, selecciona una opción',

        paymentMethod_name: 'Por favor, introduce el nombre que aparece en la tarjeta'
      },
      regex: {
        phone: 'Por favor, introduce un teléfono válido',
        shippingAddress_cp: 'Por favor, introduce un código postal válido',
        billingAddress_nif: 'Por favor, introduce un documento válido',
        billingAddress_cp: 'Por favor, introduce un código postal válido'
      },
      email: {
        contact_email: 'Por favor, introduce un correo válido'
      },
      test: {
        shippingAddress_cp: 'El código postal no coincide con la provincia',
        billingAddress_cp: 'El código postal no coincide con la provincia'
      }
    },
    saveArtwork: {
      required: {
        title: 'Por favor, introduce un título',
        author: 'Por favor, selecciona un autor',
        caption: 'Por favor, introduce una descripción corta',
        description: 'Por favor, introduce una descripción',
        frontImage: 'Por favor, selecciona una imagen de portada',
        profileImage: 'Por favor, selecciona una imagen de perfil',
        discipline: 'Por favor, escoge una disciplina',
        condition: 'Por favor, escoge una condition',
        type: 'Por favor, escoge un tipo',
        is_nft: 'Por favor, escoge Obra física o NFT',
        nftFile: 'Por favor, selecciona un archivo',
        saleTypes: 'Por favor, selecciona un tipo de venta',
        startPrice: 'Por favor, introduce un precio'
      }
    },
    smart_tag: {
      list: {
        smart_tag:'Smart Tags',
      }
    },
    artwork: {
      create_wizard: {
        step1: {},
        step2: {
          notice: {
            digital: {
              title: 'NFT',
              text:
                'La obra es un NFT y no podrá tener ningún dispositivo físico vinculado.' +
                'Una vez creada en Blockchain, la obra no se podrá modificar.'
            },
            physical: {
              title: 'Obra física',
              text: 'La obra podrá tener dispositivos vinculados físicamente. Podrás crear un NFT de esta obra en cualquier momento.'
            }
          },
          public: {
            label: 'Public Artwork',
            label_text: 'The artwork will be visible in the website',
            radio_text: 'Public',
            description_on_sell: 'If you put the work up for sale, it will be published automatically'
          }
        },
        step3: {
          check_sale: {
            label: 'Activar la venta directa o subasta',
            label_text: 'Puedes vender tu obra en cualquier momento después de crearla'
          },
          swal_is_public: {
            title: 'La obra es privada',
            text: 'Estás intentando vender una obra privada, si continuas se publicará la obra',
            button_confirm: 'Sí, hacer la obra pública',
            button_cancel: 'No, la pondré en venta más adelante'
          }
        }
      }
    }
  },
  ecommerce: {
    product: {
      subscripcion: {
        title: 'Licencias',
        description:
          'Con los planes Auth4Art entrarás en el mundo del arte digital sin riesgos y con una facilidad nunca vista'
      },
      iot: {
        title: 'IoT',
        description:
          'Monitoriza tus obras en tiempo real y garantiza: La autoría, autenticidad y Pertenencia de cada obra'
      },
      etiqueta: {
        title: 'Smart Tags',
        description: 'Registra tus obras con información concreta de la obra y documentos adjuntos.'
      },
      monthly: 'Monthly',
      first_month_included: 'First monthly payment included',
      artworks_limit: 'Artworks limit'
    },
    buttons: {
      addToCart: 'Add',
      details: 'Details'
    },
    cart: {
      empty_cart_message: 'You still do not have any products in the cart',
      summary: 'Summary',
      quantity: 'Quantity',
      total: 'Total',
      taxes: 'Taxes',
      amount: 'Amount',
      subscription: 'Subscription',
      process: 'Proccess',
      complete_order: 'Complete order',
      next: 'Next',
      total_unique: 'Unique payment',
      total_subscription: 'Subscription',
      shipping: 'Shipping'
    },
    checkout: {
      validation: {
        required: {
          shipping_address: 'You must select a shipping address',
          billing_address: 'You must select a billing address',
          payment_method: 'You must select a payment method'
        }
      },
      buttons: {
        new_payment_method: 'New payment method',
        new_address: 'New address',
        edit: 'Edit'
      },
      payment_method: 'Payment method',
      credit_card: 'Credit card',
      default: 'Default',
      shipping_data: 'Shipping data',
      details: 'Details',
      address: 'Address',
      postal_code: 'Postal code',
      city: 'City',
      province: 'Province',
      country: 'Country',
      billing_data: 'Billing data'
    },
    order: {
      order_detail: "Order's detail",
      order_status: "Order's status",
      payment_method: 'Payment method',
      shipping_address: 'Shipping address',
      product: 'Product',
      quantity: 'Quantity',
      unit_price: 'Unit price',
      price: 'Price',
      subtotal: 'Amount',
      taxes: 'Taxes',
      subscription: 'Subscription',
      shipping_costs: 'Shipping costs',
      total: 'Total',
      empty_datatable: 'You still dont have any order',
      status: {
        created: 'Created',
        paid: 'Paid',
        payment_failed: 'Payment failed',
        shipping: 'Sent',
        closed: 'Closed',
        cancelled: 'Cancelled',
      }
    }
  },
  account_profile: {
    account_details: 'Account details',
    artworks: 'Artworks',
    notices: 'Notices',
    roles: 'Roles',
    validation: {
      required: {
        current_password: 'Please write your current password'
      },
      password_too_short: 'Your password is too short.',
      password_not_match: 'Your password confirmation do not match'
    },
    forms: {
      email: 'Email',
      fullname: 'Name',
      current_password: 'Current password',
      new_password: 'New password',
      confirm_password: 'Confirm password',
      data_updated: 'Your data has been updated successfully'
    },
    buttons: {
      save: 'Save',
      manage: 'Manage',
      understood: 'Understood'
    }
  },
  form_request: {
    default_error: {
      title: 'Error',
      description: ' The request failed due to an error'
    }
  },
  breadcrumbs: {
    process_order: 'Process order',
    products: 'Products',
    my_orders: 'My orders',
    my_profile: 'My Profile'
  },
  components: {

  },
  modules:{
    authentication: {
      Item3MetamaskNetwork: {
        title: "Polygon's Network",
        faq1: {
          title: 'How do I add a network with the Metamask extension?',
          description: {
            item1: 'In the upper left, you will find a dropdown with all your networks',
            item2: 'You can add a network at any time by clicking <strong>Add Network</strong>',
            item3: 'At this point a new tab will open towards the Metamask extension, you will see all the networks you have and you can search for the network you want to add',
            item4: 'In our case, we are going to search for <strong>Polygon Mainnet</strong> and click on <strong>Save</strong>.',
            item5: 'Ready! You already have the main Polygon network enabled in your Metamask',
          },
        },
        network_details: {
          network: {
            title: 'Network Name',
            description: 'Polygon',
          },
          rpc_url: {
            title: 'New RPC URL',
            description: 'https://polygon-rpc.com',
          },
          chain_id: {
            title: 'ChainID',
            description: '137',
          },
          symbol: {
            title: 'Symbol',
            description: 'MATIC',
          },
          explorer_url: {
            title: 'Block Explorer URL',
            description: 'https://polygonscan.com',
          },
        },
      },
    },
  },
  composables: {
    subscription: {
      cancel_subscription: {
        title: '¿Estás seguro que quieres cancelar tu suscripción?',
        description: 'Se te asignará automáticamente el plan gratuito, para cancelar tu cuenta ponte en contacto con soporte'
      },
      subscription_canceled: {
        title: 'Suscripción cancelada',
        description: 'Puedes seguir usando la plataforma con las limitaciones del plan gratuito.'
      }
    }
  }
};
